.internet {
    .plan {
        .edit {
            .constraint {
                .header {
                    h4 {
                        border: 0;
                    }
                }
                .add-constraint, .edit-constraint {
                    width: 100%;
                    max-width: 500px;
                    border-radius: 10px;
                    .header {
                        h4 {
                            border: 0;
                        }
                        .buttons {
                            img {
                                width: 30px;
                                cursor: pointer;
                            }
                        }
                    }
                    form {
                        .col {
                            display: flex;
                            flex-direction: row;
                            > * {
                                flex-grow: 1;
                            }
                            .row-days {
                                margin-left: 20px;
                                max-width: 150px;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                        .time {
                            display: flex;
                            flex-direction: row;
                            :first-child {
                                margin-right: 5px;
                            }
                            :nth-child(2) {
                                margin: 0 2.5px;
                            }
                            :last-child {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}