.customer {
    .details {
        .lock {
            width: 100%;
            max-width: 500px;
            border-radius: 10px;
            .header {
                h4 {
                    border: 0;
                }
                .buttons {
                    img {
                        width: 30px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}