.prepaid {
    .customer {
        .create,
        .edit {
            max-width: 600px;
        }
        .delete {
            border-radius: 10px !important;
        }
    }
}
