.internet {
  .plan {
    .edit {
      .card {
        table {
          td {
            .actions {
              display: flex;
              flex-direction: row;
              justify-content: space-around !important;
              max-width: 65px !important;
              margin: 0 !important;
            }
          }
        }
        > .col {
          display: flex;
          flex-direction: row;
          > * {
            flex-grow: 1;
          }
          > :first-child {
            margin-right: 10px;
          }
          > :last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
