.customer {
    .details {
        .unlock {
            width: 100%;
            max-width: 300px;
            border-radius: 10px;
            .header {
                h4 {
                    border: 0;
                }
            }
        }
    }
}