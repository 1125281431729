.prepaid {
    .customers {
        overflow: scroll;
        .header {
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            h4 {
                font-family: "DM Sans Bold";
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                color: #020e23;
            }
            .buttons {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                button,
                a {
                    min-width: 100px;
                    margin: 0 5px;
                    height: 35px;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    box-sizing: border-box;
                    img {
                        margin-right: 10px;
                    }
                    cursor: pointer;
                }
                button:focus,
                a:focus {
                    outline: none;
                }
                a {
                    background-color: var(--bg-dark);
                }
                &.locations {
                    justify-content: flex-start;
                    a {
                        background-color: #fff;
                        color: #403f3f;
                        &.active {
                            background-color: var(--bg-dark);
                            color: #fff;
                        }
                    }
                }
            }
        }
        tr:hover {
            background-color: #f4f4f4;
        }
        td {
            &.online {
                background-color: #AAEE30;
                border-bottom: 0.3px solid #AAEE30;
            }
            &.expired {
                background-color: #FEEE5B;
                border-bottom: 0.3px solid #FEEE5B;
            }
            &.locked {
                background-color: #d60c09;
                border-bottom: 0.3px solid #d60c09;
                color: #fff;
            }
        }
    }
}
